import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyAAsj3JFi-GJO1NSrnMm04BklPkSaSK3AM",
    authDomain: "axis-admin.firebaseapp.com",
    projectId: "axis-admin",
    storageBucket: "axis-admin.appspot.com",
    messagingSenderId: "818112456160",
    appId: "1:818112456160:web:3175ef5c84d9d3a4c23785"
};

const app = initializeApp(firebaseConfig);
const provider = new GoogleAuthProvider();
const auth = getAuth();

export { app, auth, provider };
